<template>
  <td v-if="extraCol.value == 'color__name'">{{ colorGroup.color__name }}</td>
  <td v-else-if="extraCol.value == 'type__name'">
    {{ colorGroup.type__name }}
  </td>
  <td v-else-if="extraCol.value == 'raw_color'">
    {{ colorGroup.color__name }}
  </td>
  <td style="padding: 0" v-else-if="extraCol.value == 'action_copy'">
    <slot name="action_copy"></slot>
  </td>
  <selectable-cell v-else class="input-number-cell">
    <selected-products-edit
      v-if="extraCol.value == 'list_price'"
      :selectedProducts="getSelectedProducts(colorGroup)"
    >
      <template v-slot="{ newSelectedProduct, updateFunc }">
        <cell-input
          size="3"
          type="text"
          v-model="newSelectedProduct.list_price"
          variable-type="number"
          :label="(newSelectedProduct.product || {}).list_price"
          @blur="updateFunc()"
        />
      </template>
    </selected-products-edit>
    <selected-products-edit
      v-else-if="extraCol.value == 'net_price'"
      :selectedProducts="getSelectedProducts(colorGroup)"
    >
      <template v-slot="{ newSelectedProduct, updateFunc }">
        <cell-input
          size="3"
          type="text"
          variable-type="number"
          v-model="newSelectedProduct.net_price"
          :label="newSelectedProduct.net_price"
          @blur="updateFunc()"
        />
      </template>
    </selected-products-edit>
    <selected-products-edit
      v-else-if="extraCol.value == 'campaign_price'"
      :selectedProducts="getSelectedProducts(colorGroup)"
    >
      <template v-slot="{ newSelectedProduct, updateFunc }">
        <cell-input
          size="3"
          type="text"
          variable-type="number"
          v-model="newSelectedProduct.campaign_price"
          @blur="updateFunc()"
        />
      </template>
    </selected-products-edit>
    <cell-input
      v-else-if="extraCol.value == 'profit'"
      disabled
      :value="getColorGroupPrices(colorGroup).profit"
    />

    <cell-input
      v-else-if="extraCol.value == 'profit_percentage'"
      disabled
      :value="getColorGroupPrices(colorGroup).profit_percentage"
    />
    <cell-input
      v-else-if="extraCol.value == 'purchase_price'"
      disabled
      :value="getColorGroupPrices(colorGroup).purchase_price"
      class="green-text"
      :class="{ 'red-text': !(getSelectedProducts(colorGroup)[0] || {}).from_price_catalog }"
      :title="(getSelectedProducts(colorGroup)[0] || {}).from_price_catalog ? 'Hinta hintakatalogissa' : 'Hinta EI TULE valitusta hintakatalogista'"
    />

    <cell-input
      v-else-if="dateGroup && extraCol.value == 'quantity_sum'"
      disabled
      :value="getRowTotals(dateGroup).quantity"
    />

    <cell-input
      v-else-if="dateGroup && extraCol.value == 'expense_sum'"
      disabled
      :value="getRowTotals(dateGroup).net_price"
    />

    <cell-input
      v-else-if="dateGroup && extraCol.value == 'revenue_sum'"
      disabled
      :value="getRowTotals(dateGroup).list_price"
    />

    <cell-input
      v-else-if="dateGroup && extraCol.value == 'profit_sum'"
      disabled
      :value="getRowTotals(dateGroup).profit"
    />

    <span v-else-if="dateGroup"></span>
  </selectable-cell>
</template>

<script>
import SelectableCell from "../components/SelectableCell.vue";
import CellInput from "../components/CellInput.vue";
import SelectedProductsEdit from "../components/SelectedProductsEdit.vue";
import ColorGroupCalculations from "../mixins/ColorGroupCalculations.vue";
import OrderHelpers from "../mixins/OrderHelpers.vue";

export default {
  name: "ExtraColumn",
  props: ["colorGroup", "extraCol", "order", "dateGroup"],
  components: {
    SelectableCell,
    CellInput,
    SelectedProductsEdit,
  },
  mixins: [ColorGroupCalculations, OrderHelpers],
};
</script>

<style scoped>
.green-text {
  background-color: rgb(198, 255, 198);
}
.red-text {
  background-color: rgb(255, 192, 192) !important;
}
</style>
<template>
  <v-container class="edit-order" v-if="order">
    <v-row>
      <v-col>
        <h2>Tilaus #{{ order.id }}: {{ order.name }}</h2>
      </v-col>
      <v-col>
        <v-btn @click="saveOrder" :disabled="!changed">Tallenna</v-btn>
        <v-progress-linear
          v-if="saving"
          :indeterminate="true"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="localValues.name" label="Nimi" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="localValues.status"
          label="Status"
          :items="availableStatuses"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="localValues.supplier"
          label="Toimittaja"
          :items="
            (choices.suppliers || []).map((row) => ({
              value: row.id,
              text: row.name,
            }))
          "
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea v-model="localValues.comment" label="Kommentti" :rows="3" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="localValues.season_str"
          type="text"
          label="Sesonki"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="localValues.default_date"
          type="number"
          label="Oletus-toimitusvko"
        />
      </v-col>
      <v-col>
        <v-select
          v-if="choices.price_catalogs"
          :loading="pricesLoading"
          :disabled="pricesLoading"
          v-model="localValues.selected_price_catalog"
          label="Hintakatalogi"
          :items="
            Object.keys(choices.price_catalogs[localValues.supplier] || {}).map(
              (catalog) => {
                if (catalog === 'default') {
                  return {
                    value: null,
                    text: 'Oletushinta',
                  };
                }
                return {
                  value: catalog,
                  text: catalog,
                };
              }
            )
          "
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="localValues.discount_percentage"
          type="number"
          label="Aleprosentti NETTO-hinnasta"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="3">
        <v-select
          v-model="localValues.payment_condition"
          label="Maksuehto"
          :items="choices.payment_conditions"
        ></v-select>

        <a target="_blank" :href="`/admin/orders/paymentcondition/`"
          >Maksuehtojen hallinta</a
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="3">
        <v-select
          v-model="localValues.tekso_split_mode"
          label="Tilauksen jako (vain TEKSOON viennissä)"
          :items="choices.tekso_split_modes"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="2">
        <v-btn color="error" @click="deleteOrder">Poista tilaus</v-btn>
      </v-col>
      <v-col :cols="2">
        <v-btn @click="saveOrder" :disabled="!changed">Tallenna</v-btn>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row>
      <h2>Muokkaa tilausta...</h2>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EditOrder",
  components: {},
  data: () => ({
    pricesLoading: true,
    order: null,
    localValues: {
      name: null,
      comment: null,
      season_str: null,
      default_date: null,
      status: null,
      discount_percentage: null,
      supplier: null,
      selected_price_catalog: null,
      payment_condition: null,
      tekso_split_mode: null,
    },
    originalValues: {},
    choices: {
      status: [],
      price_catalogs: {},
      payment_conditions: [],
      tekso_split_modes: [
        ({ value: "store", text: "Varasto" }),
        ({ value: "store_date", text: "Varasto+Toimitusvko" }),
      ]
    },
    loaded: false,
    changed: false,
    saving: false,
  }),
  mounted() {
    this.fetchOrder();
    this.fetchChoices();
  },
  computed: {
    availableStatuses() {
      let badStatuses = [];
      if (!this.order) {
        return this.choices.status;
      }
      const statusToNumber = {
        draft: 0,
        new: 1,
        ready: 2,
        ready_prices: 3,
        sent: 4,
        fixing: 5,
        received: 6,
        confirmed: 7,
        exported: 8,
        cancelled: 999,
        deleted: 999,
      };
      const currentNumber = statusToNumber[this.order.status] || 0;
      if (currentNumber == 999) {
        return this.choices.status.filter(
          (status) => status.value == this.order.status
        );
      }
      if (currentNumber >= 4) {
        badStatuses = badStatuses.concat([
          "draft",
          "new",
          "ready",
          "ready_prices",
        ]);
      }
      if (currentNumber < 4) {
        badStatuses = badStatuses.concat([
          "fixing",
          "received",
          "confirmed",
          "exported",
        ]);
      }
      if (currentNumber >= 6) {
        badStatuses.push("sent");
      }
      console.log("badStatuses", badStatuses);
      return this.choices.status.filter(
        (status) => !badStatuses.includes(status.value)
      );
    },
  },
  methods: {
    setOrder(order) {
      this.order = order;
      if (this.order == null) {
        return;
      }
      this.localValues.name = this.order.name;
      this.localValues.comment = this.order.comment;
      this.localValues.default_date = this.order.default_date;
      this.localValues.status = this.order.status;
      this.localValues.discount_percentage = this.order.discount_percentage;
      this.localValues.supplier = this.order.supplier;
      this.localValues.selected_price_catalog =
        this.order.selected_price_catalog;
      this.localValues.season_str = this.order.season_str;
      this.localValues.payment_condition = this.order.payment_condition;
      this.localValues.tekso_split_mode = this.order.tekso_split_mode;

      setTimeout(() => {
        this.loaded = true;
      }, 100);
    },
    fetchOrder() {
      this.axios
        .get(`/api/orders/order/${this.$route.params.orderId}/edit/`)
        .then((resp) => {
          this.setOrder(resp.data);
        });
    },
    fetchChoices() {
      this.pricesLoading = true;
      this.axios.get(`/api/orders/choices/`).then((resp) => {
        this.choices.status = resp.data.status.map((choice) => ({
          value: choice[0],
          text: choice[1],
        }));
        this.choices.suppliers = resp.data.suppliers;
        this.choices.payment_conditions = (
          resp.data.payment_conditions || []
        ).map((row) => ({
          value: row.pk,
          text: row.name,
        }));
      });
      this.axios.get(`/api/orders/choices/?price_catalogs=1`).then((resp) => {
        this.choices.price_catalogs = resp.data.price_catalogs;
        this.pricesLoading = false;
      });
    },
    saveOrder() {
      this.saving = true;
      this.axios
        .post(
          `/api/orders/order/${this.$route.params.orderId}/edit/`,
          this.localValues
        )
        .then((resp) => {
          this.setOrder(resp.data);
          this.changed = false;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    deleteOrder() {
      if (
        !confirm(
          `Poistetaanko tilaus #${this.$route.params.orderId} ${this.order.name}?`
        )
      ) {
        return;
      }
      this.axios
        .delete(`/api/orders/order/${this.$route.params.orderId}/edit/`)
        .then((resp) => {
          this.setOrder(null);
          this.$store.commit("addAlert", {
            type: "success",
            text: `Poistettu tilaus #${this.$route.params.orderId}`,
          });
          this.$router.push("/orders");
        });
    },
  },
  watch: {
    localValues: {
      deep: true,
      handler() {
        if (!this.loaded) {
          return;
        }
        this.changed = true;
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>

<script>
// Includues choices variable in component
export default {
  name: "ChoicesOrder",
  data: () => ({
    choices: {},
  }),
  mounted() {
    this.fetchOrderChoices();
  },
  methods: {
    fetchOrderChoices() {
      //Get all possible stores.
      this.axios.get("/api/orders/choices/").then((resp) => {
        this.choices = { ...this.choices, ...resp.data };
        this.choices.stores = this.choices.stores.map((storeChoice) => {
          storeChoice.abbr = storeChoice.name;
          if (storeChoice.name == "Tampere") {
            storeChoice.abbr = "Tmpr";
          }
          if (storeChoice.name == "Tammisto") {
            storeChoice.abbr = "Vant";
          }
          if (storeChoice.name == "Kuninkoja") {
            storeChoice.abbr = "Kunk";
          }
          if (storeChoice.name == "Varasto") {
            storeChoice.abbr = "Assa";
          }
          if (storeChoice.name == "Pirkkala") {
            storeChoice.abbr = "Pirk";
          }
          if (storeChoice.name == "Forum") {
            storeChoice.abbr = "Foru";
          }
          return storeChoice;
        });
      });
    },
  },
  computed: {},
  watch: {},
};
</script>
